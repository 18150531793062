.quizlib-incorrect, .quizlib-question-warning {
    color: red;
}

.quizlib-question-warning {
    margin-left: 1em;
}

.quizlib-correct {
    color: green;
}

.quiz--hidden {
    display: none;
}

.quiz-teaser {
    background: beige;

    &__text {
        padding-top: 2em;
    }

    &__price {
        padding-top: 0.8em;
        font-size: 0.8em;
    }
}

.quiz-success, .quiz-try-again {
    margin-top: 2em;
    margin-bottom: 2em;
}

.quizlib-question {
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: none;
}

#quiz ul {
    list-style: none;
}

#quiz-result {
    margin-top: 2em;
    padding: 0.2em 0.8em;
}

.quiz-form-share-link {
    margin-top: 4em;

    &__link {

    }

    &__text {
        padding-top: 1.2em;
    }
}

.quiz-divider {
    margin-top: 2em;
    margin-bottom: 3em;
}