@import "./navbar-animation.scss";

.navbar-info {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);


    &.is-transparent.headroom--top {
        background: transparent;

        .navbar-item {
            font-weight: bold;
        }
    }

    &.navbar-info--has-border {
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) ;
    }

    .navbar-brand > .navbar-item:first-child {
        padding-left: 30px;

        @media screen and (min-width: 1024px) {
            padding-left: 0;
        }
    }

    .navbar-burger {
        position: relative;
        top: 27px;
        margin-left: auto;

        @media screen and (max-width: 768px) {
            top: 0;
            margin-left: auto;
        }
    }

    .navbar__quiz {
        // width: 75px;
        margin-left: auto;
        margin-top: 20px;
        margin-right: 40px;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
