$responsive-font-factor: 0.8;

.layout-events {
    font-family: "Open Sans", sans-serif;
    color: #838383;
    font-size: 18px;

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        flex: 1;

        .section:last-child {
            padding-bottom: 8em;
        }
    }
}

.layout-events.layout-events--rich-header {
    .layout__main {
        .section:first-child {
            margin-bottom: 2em;
        }
    }
}

.layout-events:not(.layout-events--rich-header) {
    .layout-events__main {
        .section:first-child {
            margin-top: $navbar-height;
        }
    }
}

@media screen and (max-width: 768px) {
    .content h1 {
        font-size: $responsive-font-factor * 2em;
    }

    .content h2 {
        font-size: $responsive-font-factor * 1.75em !important;
    }

    .content h3 {
        font-size: $responsive-font-factor * 1.5em !important;
    }
}