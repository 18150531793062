.challenge-activity {
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 6em !important;
    }

    &__screenshot {
        max-width: 100%;
        max-height: 12em;

        @media screen and (max-width: 768px) {
            margin-top: 1em;
        }
    }

    &__label {
        display: inline-block;
        min-width: 6em;
    }
}

.challenge-activity:first-child {
    @media screen and (max-width: 768px) {
        margin-top: 0em !important;
    }
}

.challenge-section {
    margin-top: 2em;
    margin-bottom: 2em;
}

.challenge-score {
    display: inline-block;
    width: 4em;
    text-align: right;
}