.courses-list {
    // padding-left: 4rem;
    margin-top: 2rem !important;

    &__category {
        // border-left: solid 3rem lightgrey;
        // min-height: 30em;

        h2 {
            // display: inline-block;
            background: lightgrey;
            color: white;
            padding: 0.2em;
            // transform: translateX(-100%) translateX(-2.5rem) rotate(-90deg) translateX(-4.8rem);
            // transform-origin: 100% 0%;
        }
    }

    ul {
        // padding-left: 2rem;
        padding-bottom: 2rem;
        list-style: none;
        margin-left: 0;
        // padding-left: 1rem;

        li {
            margin-bottom: 1rem;
        }
    }
}
