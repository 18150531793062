.event-list {
    list-style: none !important;
    margin-left: 0 !important;
}

.event-list-item {
    margin-bottom: 2em !important;

    &__title {
        margin-bottom: 0.25em !important;
    }
}
