.event-details {
    &__main {
        margin-bottom: 2em;
    }

    &__registration {
        background:rgba(211, 211, 211, 0.3);
        padding-top: 1.5em;
        padding-bottom: 2em;
        padding-left: 1.5em;
        padding-right: 1.5em;

        ::placeholder {
            color: rgba(54, 54, 54, 0.7);
        }

        :-ms-input-placeholder {
            color: rgba(54, 54, 54, 0.7);
        }

        ::-ms-input-placeholder {
            color: rgba(54, 54, 54, 0.7);
        }

        @media screen and (max-width: 768px) {
            margin-top: 3em;
            margin-bottom: 2em;
        }
    }

    @media screen and (min-width: 769px) {
        flex-direction: row-reverse;
    }
}

.event-details--reverse-order-on-large-screens > .columns {
    @media screen and (min-width: 769px) {
        flex-direction: row-reverse;
    }
}

.event-details--gap {
    @media screen and (min-width: 768px) {
        .event-details__main {
            padding-right: 4em;
        }
    }
}

.event-date {
    font-size: 1.25rem;
    margin-top: 1em;
    margin-bottom: 2em;

    @media screen and (max-width: 768px) {
        margin-bottom: 1em;
        font-size: 1rem;
    }
}
