.wishlist {
    ul {
        list-style: none;
        margin-left: 0;
        margin-bottom: 2em;
    }

    &__form--hidden {
        display: none;
    }

    &__divider {
        margin-bottom: 2em;
    }
}

.wishlist-share-link {
    margin-top: 2em;
    margin-bottom: 0em;
    &__text {
        padding-top: 1.2em;
    }
}