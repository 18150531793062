.logo {
    // padding-top: 0.5em;
    .fa {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 768px) {
        max-width: 120px;
    }
}
