.slider {
    width: 100%;
}

.slider-item {
    width: 100%;
    height: 60vw;
    // min-height: 60vh;
    max-height: 80vh;
    img {
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
    }
}
