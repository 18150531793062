@import "./navbar-animation.scss";

.navbar-events {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    &.is-transparent.headroom--top {
        background: transparent;
        border-bottom: none;

        .navbar-item {
            font-weight: bold;
        }
    }

    &.navbar-events--has-border {
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) ;
    }

    .navbar-brand {
        margin-top: 0.5em;

        .navbar-item {
            @media screen and (min-width: 1024px) {
                padding-left: 0;
            }
        }
    }

    .navbar-burger {
        position: relative;
        top: 20px;
    }
}
