.employee {
    text-align: center;

    &__image {

        img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
        }
    }

    &__name {
        margin-top: 0.2em !important;
        margin-bottom: 0.2em !important;
    }
}