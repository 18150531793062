.form {
    margin-top: 2em;

    &__newsletter {
        margin-top: 2em;
        margin-bottom: 2em !important;
    }

    &__privacy {
        margin-top: 1em;
        margin-bottom: 1.2em !important;
    }
}