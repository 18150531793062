.hexlink {
    text-align: center;

    &__title, &__title:hover, &__title:active, &__title:visited {
        color: white;
    }

    &__title {
        background: rgba(0, 0, 0, 0.3);
        padding-left: 0.5em;
        padding-right: 0.5em;
        border-radius: 3px;
    }
}

.hexlink-container {
    display: flex;
    justify-content: center;
}
