$font-factor: 0.6;

.hero-header-content {
    padding-bottom: 2em;

    &, &__container, .container {
        height: 100%;
    }

    &__container {
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    &__subtitle,  &__title {
        color: $primary;
    }

    &__title {
        line-height: 1.2;
        margin-top: 1em;
        margin-bottom: 1em;

        text-transform: uppercase;
    }

    &__subtitle {
        line-height: 1.2;

        margin-bottom: 1em;
    }

    &__cta {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 768px) {
    .hero-header-content__title h2 {
        font-size: $font-factor * 3rem !important;
    }

    .hero-header-content__subtitle h3 {
        font-size: $font-factor * 2.5rem !important;
    }

    .hero-header-content__tagline p {
        font-size: 1.1 * $font-factor * 1.5rem !important;
    }
}

// @media screen and (max-width: 480px) {
//     .hero-header-content__title h2 {
//         font-size: $font-factor * $font-factor * 3rem !important;
//     }

//     .hero-header-content__subtitle h3 {
//         font-size: $font-factor * $font-factor * 2.5rem !important;
//     }

//     .hero-header-content__tagline p {
//         font-size: $font-factor * $font-factor * 1.5rem !important;
//     }
// }
