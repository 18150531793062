.layout-info {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        flex: 1;

        .section:last-child {
            padding-bottom: 8em;
        }

        .breadcrumbs + h1 {
            margin-top: 0.5rem !important;
        }
    }

    &.layout-info--rich-header {
        .layout__main {
            .section:first-child {
                margin-bottom: 2em;
            }
        }
    }

    &:not(.layout-info--rich-header) {
        .layout-info__main {
            .section:first-child {
                margin-top: $navbar-height;
            }
        }
    }
}
