$hero-header-aspect-ratio: 0.56;
$hero-header-height: $hero-header-aspect-ratio * 100vw;
$hero-header-min-height: 33vh;
// $hero-header-max-height: 66vh;
$hero-header-max-height: 98vh;

.hero-header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 10%;
    height: $hero-header-height;
    min-height: $hero-header-min-height;
    max-height: $hero-header-max-height;

    display: flex;
    flex-direction: column;

    &__content {
        flex: 1;
        padding-top: $navbar-height + 3rem;
        // padding-bottom: 2em;
    }

    &__scroll-indicator {
        flex: 0;

        text-align: center;
        padding-top: 1em;
        padding-bottom: 1em;

        span {
            font-size: 2rem;
            color: rgba(0, 0, 0, 0.1);
        }
    }
}

.hero-header--no-height-constraints-up-to-widescreen {
    @media screen and (max-width: 1216px) {
        height: auto;
        min-height: none;
        max-height: none;
    }
}
