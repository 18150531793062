.hexagon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #383d40;
  clip-path: url(#hex-clip);
  width: 250px;
  height: 250px;

  background-size: cover;
  background-repeat: no-repeat;
}
