.footer-events {
    font-size: 0.7rem;
    color: white;
    padding: 0;

    &__top {
        background: #383d40;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    &__bottom {
        background: #121212;
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

.footer-events-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.8em;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    ul {
        list-style: none !important;
        margin-left: 0 !important;

        li {
            display: inline;

            @media screen and (max-width: 768px) {
                display: block;
            }

            a, a:hover, a:active, a:visited {
                color: white;
            }
        }

        li:not(:last-child) {
            margin-right: 2em;
        }
    }
}