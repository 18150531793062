@charset "utf-8";

// bulma variables
$navbar-breakpoint: 6000px;
$primary: #692a6c;
$body-size: 21px;

// navbar
$navbar-height: 3rem;
$navbar-item-img-max-height: none;
$footer-padding: 2em;

// Import all of Bulma
@import "~bulma/bulma";

// global styles
@import "./icons.scss";
@import "./fonts.scss";

// layouts
@import "./layouts/layout-info.scss";
@import "./layouts/layout-events.scss";

// components
@import "./components/navbar-info.scss";
@import "./components/navbar-events.scss";
@import "./components/logo.scss";
@import "./components/footer-info.scss";
@import "./components/footer-events.scss";
@import "./components/gallery.scss";
@import "./components/slider.scss";
@import "./components/map.scss";
@import "./components/cookie-consent.scss";
@import "./components/courses-list.scss";
@import "./components/course-details.scss";
@import "./components/hexagon.scss";
@import "./components/hexlink.scss";
@import "./components/banner.scss";
@import "./components/wishlist.scss";
@import "./components/form.scss";
@import "./components/breadcrumbs.scss";
@import "./components/hero-header.scss";
@import "./components/hero-header-content.scss";
@import "./components/event-details.scss";
@import "./components/employee-list.scss";
@import "./components/employee.scss";
@import "./components/event-list.scss";
@import "./components/checkbox-validation.scss";
@import "./components/quiz.scss";
@import "./components/challenge-activity.scss";
